import React from 'react'
import styled from 'styled-components'
import common from '../../styles/common.module.css'
import { LargeRunningText, LinkFont, BlueWord, H1, H3 } from '../../styles/styled/commonText'
import phone from '../../images/icons/phone.svg'
import chat from '../../images/icons/chat_black.svg'
import email from '../../images/icons/email_black.svg'
import { fadeUpWrapper } from '../../animations/ScrollFadeUpAnimation'
import { SpacingSizes } from '../../../clarity'
import { INFO_WORTY_EMAIL, WORTHY_PHONE } from '../../static-content/constants'
import { renderOnMount } from '../forceUpdate'
import { useBP } from '../../data/objects/breakpoints'
import { to } from '../../styles/constants'
import { GA } from '../../data/GA'

const contactChannelsData = [
  {
    icon: phone,
    title: 'Call us',
    text: (
      <LargeRunningText>
        Speak with one of our sales consultants:{' '}
        <LinkFont>
          <BlueWord
            className={common.pointer}
            onClick={() => {
              GA.callUs()
            }}
          >
            1{WORTHY_PHONE}
          </BlueWord>
        </LinkFont>
        .
      </LargeRunningText>
    ),
  },
  {
    icon: chat,
    title: 'Chat with us',
    text: (
      <LargeRunningText>
        Get your questions answered right away with one click{' '}
        <LinkFont>
          <BlueWord
            className={['intercom_chat_link', common.pointer]}
            onClick={() => {
              GA.chatWithUsContact()
            }}
          >
            right here
          </BlueWord>
        </LinkFont>
        .
      </LargeRunningText>
    ),
  },
  {
    icon: email,
    title: 'Email us',
    text: (
      <LargeRunningText>
        Drop us a line at{' '}
        <LinkFont>
          <BlueWord
            className={common.pointer}
            onClick={() => {
              GA.emailUsContact()
            }}
          >
            {INFO_WORTY_EMAIL}
          </BlueWord>
        </LinkFont>{' '}
        and we’ll be sure to get back to you.
      </LargeRunningText>
    ),
  },
]

const SectionTitle = fadeUpWrapper(styled(H1)`
  margin-bottom: ${SpacingSizes.xl};
`)

const ContactIcon = styled.img`
  height: 32px;
  width: 32px;
  margin-bottom: ${SpacingSizes.smd};

  @media ${to.bpB} {
    margin-bottom: ${SpacingSizes.md};
  }
`

const ContactChannels = fadeUpWrapper(styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${SpacingSizes.xxl};
  @media ${to.bpC} {
    padding: 0 ${SpacingSizes.xsm};
  }
  @media ${to.bpB} {
    gap: ${SpacingSizes.xl};
  }
`)

const ChannelBox = styled.div`
  max-width: 300px;
  margin: 0 ${SpacingSizes.xsm};
  @media ${to.bpB} {
    margin: 0 auto;
  }
`

const ChannelTitle = styled(H3)`
  margin-bottom: ${SpacingSizes.sm};
`

const ContactUs = () => {
  const { fitsB } = useBP().get()
  const renderKey = renderOnMount()
  const Channel = fitsB ? fadeUpWrapper(ChannelBox) : ChannelBox

  return (
    <>
      <SectionTitle className={[common.dFlex, common.flexJustifyCenter]}>We’re available.</SectionTitle>
      <ContactChannels
        className={[common.dFlex, common.flexJustifyCenter, fitsB && common.flexCol, fitsB && common.flexAlignCenter]}
        key={renderKey}
        data-automation="HIW-contactChannel-weAreAvailable"
      >
        {contactChannelsData.map(channel => (
          <Channel
            className={[common.dFlex, common.flexCol, fitsB && common.flexAlignCenter, fitsB && common.textCenter]}
            key={channel.title}
          >
            <ContactIcon src={channel.icon} alt={channel.title} />
            <ChannelTitle>{channel.title}</ChannelTitle>
            {channel.text}
          </Channel>
        ))}
      </ContactChannels>
    </>
  )
}
export default ContactUs
