import React from 'react'
import styled from 'styled-components'
import common from '../../styles/common.module.css'
import ringExam from '../../images/HIW/previews/ring_exam.svg'
import auctionPage from '../../images/HIW/previews/auction_page.svg'
import partyArrow from '../../images/HIW/previews/party_arrow.svg'
import { useBP } from '../../data/objects/breakpoints'
import { to } from '../../styles/constants'
import { renderOnMount } from '../forceUpdate'
import {
  SpacingSizes,
  GENERAL_FONT_VALUES,
  GeneralFontNames,
  GENERAL_COLOR_VALUES,
  GeneralColorNames,
} from '../../../clarity'

const stepPreviews = [
  {
    icon: ringExam,
    text: 'Prep for sale',
  },
  {
    icon: auctionPage,
    text: 'Auction online',
  },
  {
    icon: partyArrow,
    text: 'Get paid',
  },
]

const Steps = styled.ol`
  margin: ${SpacingSizes.xxl} 0 ${SpacingSizes.xxxl};

  @media ${to.bpB} {
    margin: ${SpacingSizes.xxl} 0;
  }
`

const Step = styled.li`
  margin: 0 ${SpacingSizes.xsm};
  width: 300px;

  @media ${to.bpB} {
    margin: 0 ${SpacingSizes.smd};
    &:not(:last-of-type){
      margin-bottom: ${SpacingSizes.xl};
    }
  }
`
const StepName = styled.div`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_22]};
`

const StepImage = styled.img`
  max-width: 160px;
  margin-bottom: ${SpacingSizes.md};
`

const StepNumber = styled.div`
  height: 25px;
  width: 25px;
  margin-right: ${SpacingSizes.xs};
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]};
  align-items: flex-end;
`

const StepsPreview = () => {
  const renderKey = renderOnMount()
  return (
    <Steps
      className={[
        common.dFlex,
        common.flexAlignCenter,
        common.flexJustifyCenter,
        useBP().get().fitsB && common.flexCol,
      ]}
      key={renderKey}
    >
      {
        stepPreviews.map((step, i) => (
          <Step key={step.text} className={[common.dFlex, common.flexCol, common.flexAlignCenter]}>
            <StepImage src={step.icon} alt={step.text} />
            <StepName className={[common.dFlex, common.flexAlignCenter]}>
              <StepNumber className={[
                common.dFlex,
                common.flexJustifyCenter,
              ]}
              >
                {i + 1}
              </StepNumber>
              {step.text}
            </StepName>
          </Step>
        ))
    }
    </Steps>
  )
}
export default StepsPreview
