import React, { useState, useRef, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import common from '../../styles/common.module.css'
import play from '../../images/icons/play.svg'
import { BackgroundSection } from '../commonStyled/sectionsWithBackground'
import { YoutubePopup } from '../homepage/testimonials/YoutubePopup'
import { Mega } from '../../styles/styled/commonText'
import bgPattern from '../../images/HIW/HIW_pattern.svg'
import { renderOnMount } from '../forceUpdate'
import { useBP } from '../../data/objects/breakpoints'
import { from, size, to } from '../../styles/constants'
import { fadeUpWrapper } from '../../animations/ScrollFadeUpAnimation'
import {
  GENERAL_COLOR_VALUES,
  GeneralColorNames,
  GENERAL_FONT_VALUES,
  GeneralFontNames,
  SpacingSizes,
  LineHeights,
} from '../../../clarity'
import { GA } from '../../data/GA'

const ImagesContainer = styled.div``

const Section = styled(BackgroundSection)`
  padding: ${SpacingSizes.xxl} ${SpacingSizes.md};

  @media ${to.bpC} {
    padding: ${SpacingSizes.xxl} ${SpacingSizes.md};
  }
`

const Fader = fadeUpWrapper(styled.div`
  @media (min-width: ${size.b}) and (max-width: ${size.c}) {
    max-width: 712px;
    margin: 0 auto;
  }
`)

const PlayContainer = styled.div`
  bottom: ${SpacingSizes.md};
  left: ${SpacingSizes.md};
  height: 88px;

  @media ${to.bpC} {
    bottom: calc(50% - 44px); // icon is 88px
    left: calc(50% - 44px); // icon is 88px
  }
`

const SectionTitle = styled(Mega)`
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_900]};
  @media ${from.bpD} {
    width: 400px;
    font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_60]};
    line-height: ${LineHeights[82]};
    margin-right: ${SpacingSizes.xl};
  }

  @media (min-width: ${size.c}) and (max-width: ${size.d}) {
    margin-right: ${SpacingSizes.md};
  }

  @media ${to.bpC} {
    margin-bottom: ${SpacingSizes.xl};
  }
`

const YouTubeReferrer = () => {
  const [showVideo, setShowVideo] = useState(false)
  const sectionRef = useRef<HTMLInputElement>(null)
  const [containerWidth, setFullWidth] = useState(0)
  const renderKey = renderOnMount()
  const data = useStaticQuery(graphql`
    fragment YouTubeHIW on File {
      childImageSharp {
        fluid(maxWidth: 800, quality: 75) {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }
    query YouTubeHIWImageList {
      commercial: file(relativePath: { eq: "HIW/youtube/commercial_bg.jpg" }) {
        ...YouTubeHIW
      }
      commercialMobile: file(relativePath: { eq: "HIW/youtube/commercial_bg_mobile.jpg" }) {
        ...YouTubeHIW
      }
      commercial771: file(relativePath: { eq: "HIW/youtube/commercial_bg_771.jpeg" }) {
        ...YouTubeHIW
      }
    }
  `)

  useEffect(() => {
    setFullWidth(sectionRef.current?.clientWidth || 0)
  }, [])

  const { fitsB, fitsD, fitsC } = useBP().get()
  const isMobile = fitsB
  const widthOptions = () => {
    let width = containerWidth * 0.6
    if (fitsD) {
      width = containerWidth * 0.6 - 15
    }
    if (fitsC) {
      width = containerWidth - 48
    }
    return width
  }
  const photoOptions = () => {
    let field = ''
    if (fitsC) {
      field = '771'
    }
    if (isMobile) {
      field = 'Mobile'
    }
    return field
  }
  return (
    <Section
      key={renderKey}
      bgPattern={bgPattern}
      bgColor={GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]}
      height="fit-content"
      bgPosition="center"
      bgRepeat="no-repeat"
      ref={sectionRef}
    >
      <Fader
        className={[
          common.dFlex,
          common.flexAlignCenter,
          common.flexJustifyCenter,
          fitsC && common.flexCol,
          fitsC && common.textCenter,
        ]}
      >
        <SectionTitle>A seamless, end to end, jewelry selling experience.</SectionTitle>
        <ImagesContainer className={[common.posRel]}>
          <Img
            alt="video preview"
            fluid={data[`commercial${photoOptions()}`].childImageSharp.fluid}
            style={{
              maxWidth: '690px',
              width: `${widthOptions()}px`,
              position: 'relative',
            }}
          />
          <PlayContainer
            className={[common.pointer, common.posAbs]}
            data-automation="YouTubeReferrer-PlayContainer"
            onClick={() => {
              setShowVideo(true)
              GA.youtubeEvent()
            }}
          >
            <img src={play} alt="play icon" style={{ width: '88px' }} />
          </PlayContainer>
        </ImagesContainer>
        {showVideo && <YoutubePopup onClose={() => setShowVideo(false)} videoId="PGm08xmfkcY" />}
      </Fader>
    </Section>
  )
}
export default YouTubeReferrer
