import React from 'react'
import Layout from '../components/layout/layout'
import MobileLayout from '../components/layout/mobileLayout'
import SEO from '../components/layout/seo'
import StepsPreview from '../components/HIW/stepsPreview'
import OurProcess from '../components/HIW/ourProcessHIW'
import YouTubeReferrer from '../components/HIW/youTubeReferrer'
import ContactUs from '../components/HIW/contactUs'
import RecommendedLinks from '../components/shared/recommendedLinks'
import PageTitle from '../components/shared/pageTitle'
import { useBP } from '../data/objects/breakpoints'
import { renderOnMount } from '../components/forceUpdate'

const pageForGAEvents = 'How it Works page'

const HowItWorks = () => {
  const renderKey = renderOnMount()
  const { fitsB } = useBP().get()
  const metaDesc =
    'Get the most for your valuables with Worthy@ online jewelry auction. Free secure pickup. We do all the work. you get paid! That@ how it works! Learn more now!'
  const main = (
    <>
      <SEO title="How it works | Online Jewelry Auction " description={metaDesc} />
      <PageTitle
        title="How It Works"
        subTitle="How we help get you the most for your jewelry. It's as easy as 1-2-3."
      />
      <StepsPreview />
      <OurProcess />
      <YouTubeReferrer />
      <RecommendedLinks
        relevantLinks={['FAQs', 'Reviews', 'Recently sold']}
        page={pageForGAEvents}
        dataAutomationPrefix="HIW"
      />
      <ContactUs />
    </>
  )

  return useBP().get().fitsB ? (
    <MobileLayout key={renderKey} page={pageForGAEvents}>
      {main}
    </MobileLayout>
  ) : (
    <Layout key={renderKey} page={pageForGAEvents}>
      {main}
    </Layout>
  )
}
export default HowItWorks
