import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GENERAL_COLOR_VALUES, GeneralColorNames, SpacingSizes, LineHeights, ModalPattern } from '../../../clarity'
import { LargeRunningText, H1 } from '../../styles/styled/commonText'
import common from '../../styles/common.module.css'
import { fadeUpWrapper } from '../../animations/ScrollFadeUpAnimation'
import { to } from '../../styles/constants'
import { useBP } from '../../data/objects/breakpoints'
import { renderOnMount } from '../forceUpdate'
import { BoxIcon } from '../homepage/faqSection/faqSection'
import { FAQPopup, FAQPopupContent } from '../homepage/faqSection/faqPopup'
import { ProcessImage, RightOrientation, LeftOrientation, SubSection } from '../../styles/styled/sharedOurProcess'

import { Step, Steps } from './ourProcessData'
import { GA } from '../../data/GA'

const Section = styled.section`
  width: fit-content;

  @media ${to.bpB} {
    width: auto;
  }
`

const ContentArea = styled.div`
  padding-top: ${SpacingSizes.xl};

  @media ${to.bpB} {
    padding-top: ${SpacingSizes.md};
  }
`

const SubStepsContainer = fadeUpWrapper(styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: ${SpacingSizes.sm};
  max-width: 600px;
  padding-right: ${SpacingSizes.sm};

  @media ${to.bpB} {
    grid-template-columns: 100%;
    padding-right: 0;
  }
`)

const ContentAreaInner = styled.div``

const SubSectionTitle = fadeUpWrapper(styled(H1)`
  margin-bottom: ${SpacingSizes.md};
`)

const Numeral = styled.div`
  height: 65px;
  width: 65px;
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]};
  padding-bottom: ${SpacingSizes.xs};
  margin-bottom: ${SpacingSizes.md};

  @media ${to.bpB} {
    position: absolute;
    bottom: 0;
    border-top: 6px solid ${GENERAL_COLOR_VALUES[GeneralColorNames.worthy_white]};
    border-right: 6px solid ${GENERAL_COLOR_VALUES[GeneralColorNames.worthy_white]};
    margin-bottom: 0;
  }
`

const ImgWrapper = styled.div`
  position: relative;
`

const QuestionLabel = styled(LargeRunningText)`
  line-height: ${LineHeights[60]};
  margin-left: ${SpacingSizes.xs};
`

const StepDesc = fadeUpWrapper(styled(LargeRunningText)`
  margin-bottom: ${SpacingSizes.xlg};

  @media ${to.bpB} {
    margin-bottom: ${SpacingSizes.md};
  }
`)

const SubStepBoxLargeScreen = styled.button`
  flex-basis: calc(50% - 30px);
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]};
  height: 60px;
  padding-left: ${SpacingSizes.sm};
  border-radius: 6px;
  max-width: 300px;
  border: 0;
  &:hover {
    box-shadow: -4px 4px ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_400]};
    transition: box-shadow 0.3s ease-in;
  }

  @media ${to.bpA} {
    flex-basis: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0 ${SpacingSizes.sm};
    max-width: 516px;
  }
`

const SubStepBoxSmallScreen = fadeUpWrapper(SubStepBoxLargeScreen)

const content = (
  step: Step,
  setShowPopup: Function,
  setPopupText: Function,
  setPopupTitle: Function,
  stepNumber: number
) => {
  const { fitsB } = useBP().get()

  const SubStepBox = fitsB ? SubStepBoxSmallScreen : SubStepBoxLargeScreen
  const StepNumeral = fitsB ? Numeral : fadeUpWrapper(Numeral)

  return (
    <ContentArea className={[common.dFlex, common.flexCol]}>
      <ContentAreaInner
        className={[common.posRel, common.dFlex, common.flexCol, step.reverse && !fitsB && common.flexAlignEnd]}
      >
        {!fitsB && (
          <StepNumeral
            className={[common.dFlex, common.flexJustifyCenter, common.flexAlignCenter, step.reverse && common.fRight]}
          >
            <H1>{stepNumber}</H1>
          </StepNumeral>
        )}
        <SubSectionTitle>{step.title}</SubSectionTitle>
        <StepDesc className={[common.dBlock]}>{step.text}</StepDesc>
        <SubStepsContainer className={[common.w100, !fitsB && step.reverse && common.fRight]}>
          {step.subSteps.map(subStep => (
            <SubStepBox
              className={[common.dFlex, common.pointer, common.w100]}
              key={subStep.key}
              tabIndex={0}
              data-automation={`HIW-subStep-${subStep.key}`}
              onClick={() => {
                setPopupText(subStep.desc)
                setPopupTitle(subStep.title)
                setShowPopup(true)
                GA.processEvent(subStep.btnText)
              }}
            >
              <BoxIcon src={subStep.icon} alt="" />
              <QuestionLabel>{subStep.btnText}</QuestionLabel>
            </SubStepBox>
          ))}
        </SubStepsContainer>
      </ContentAreaInner>
    </ContentArea>
  )
}

const OurProcess = () => {
  const sectionRef = useRef<HTMLInputElement>(null)
  const data = useStaticQuery(graphql`
    fragment ourProcessHIW on File {
      childImageSharp {
        fluid(maxWidth: 780, quality: 75) {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }
    query OurProcessHIWImageList {
      prepping: file(relativePath: { eq: "HIW/ourProcess/preppingYourJewelry.jpg" }) {
        ...ourProcessHIW
      }
      prepping770: file(relativePath: { eq: "HIW/ourProcess/preppingYourJewelry770.jpeg" }) {
        ...ourProcessHIW
      }
      auction: file(relativePath: { eq: "HIW/ourProcess/onlineAuction.jpg" }) {
        ...ourProcessHIW
      }
      auction770: file(relativePath: { eq: "HIW/ourProcess/onlineAuction770.jpeg" }) {
        ...ourProcessHIW
      }
      payment: file(relativePath: { eq: "HIW/ourProcess/gettingPaid.jpg" }) {
        ...ourProcessHIW
      }
      paymentMobile: file(relativePath: { eq: "HIW/ourProcess/gettingPaidMobile.jpg" }) {
        ...ourProcessHIW
      }
      payment770: file(relativePath: { eq: "HIW/ourProcess/gettingPaid770.jpeg" }) {
        ...ourProcessHIW
      }
    }
  `)
  const { fitsB, fitsC } = useBP().get()
  const isMobile = fitsB
  const renderKey = renderOnMount()
  const [showPopup, setShowPopup] = useState(false)
  const [popupText, setPopupText] = useState('')
  const [popupTitle, setPopupTitle] = useState('')
  const [viewPortWidth, setFullWidth] = useState(0)
  const stepsToRender = Steps
  const widthRelationByBreakpoint = fitsC ? 0.33 : 0.5
  useEffect(() => {
    setFullWidth(sectionRef.current?.clientWidth || 0)
  }, [])

  return (
    <>
      <Section
        ref={sectionRef}
        className={[common.dFlex, common.flexCol, common.w100, common.margin0auto, common.posRel]}
      >
        {stepsToRender.map((step: Step, idx: number) => {
          const photoOptions = () => {
            let field = step.pic.desktop
            if (fitsC) {
              field = step.pic.breakPoint770
            }
            if (isMobile && step.pic.mobile) {
              field = step.pic.mobile
            }
            return field
          }
          const stepNumber = idx + 1
          return (
            <SubSection
              key={`our-pocess-section-${step.title}-${renderKey}`}
              spacing={!isMobile ? SpacingSizes.xxl : undefined}
              className={[
                common.w100,
                isMobile ? common.flexCol : null,
                common.dFlex,
                common.flexJustifyCenter,
                isMobile ? null : step.reverse && common.flexRowReverse,
              ]}
            >
              <ImgWrapper>
                <ProcessImage
                  width={viewPortWidth}
                  fluid={data[photoOptions()].childImageSharp.fluid}
                  alt={step.title}
                  widthRelation={widthRelationByBreakpoint}
                />
                {isMobile && (
                  <Numeral className={[common.dFlex, common.flexJustifyCenter, common.flexAlignCenter]}>
                    <H1>{stepNumber}</H1>
                  </Numeral>
                )}
              </ImgWrapper>
              {step.reverse && !isMobile ? (
                <RightOrientation className={[common.textRight]}>
                  {content(step, setShowPopup, setPopupText, setPopupTitle, stepNumber)}
                </RightOrientation>
              ) : (
                <LeftOrientation>
                  {content(step, setShowPopup, setPopupText, setPopupTitle, stepNumber)}
                </LeftOrientation>
              )}
            </SubSection>
          )
        })}
      </Section>
      {showPopup &&
        (fitsB ? (
          <ModalPattern
            key="ModalPattern"
            closeIcon
            closeOnClickOutside
            isWide
            overrideCSS={{
              paddingTop: '52px',
              minHeight: '550px',
            }}
            onClose={() => setShowPopup(false)}
          >
            <FAQPopupContent
              q={{
                key: '',
                question: popupTitle,
                answer: popupText,
                icon: '',
                btnText: '',
              }}
            />
          </ModalPattern>
        ) : (
          <FAQPopup
            question={{
              key: '',
              question: popupTitle,
              answer: popupText,
              icon: '',
              btnText: '',
            }}
            closePopup={() => {
              setShowPopup(false)
            }}
          />
        ))}
    </>
  )
}

export default OurProcess
