import React from 'react'
import { renderToString } from 'react-dom/server'
import truck from '../../images/icons/truck.svg'
import bubbles from '../../images/icons/bubbles.svg'
import camera from '../../images/icons/camera.svg'
import magnifyingGlass from '../../images/icons/magnifying_glass.svg'
import auctionListing from '../../images/icons/auction_listing.svg'
import priceTag from '../../images/icons/price_tag.svg'
import auctionOffer from '../../images/icons/auction_offer.svg'
import click from '../../images/icons/click.svg'
import bankTransfer from '../../images/icons/bank_transfer.svg'
import timer from '../../images/icons/timer.svg'
import coins from '../../images/icons/coins.svg'
import { SpacingSizes } from '../../../clarity'
import { FeesTable } from '../commonStyled/feesTable'

export interface Step {
  title: string
  text: string
  pic: { desktop: string; mobile: string; breakPoint770: string }
  reverse?: boolean
  subSteps: Array<any>
}

export const Steps: Step[] = [
  {
    title: 'Preparing Your Jewelry',
    text:
      'The preparation process includes cleaning, photography, and objective third-party grading of your jewelry. FedEx Shipment to our offices is fully insured by insurers at Lloyd’s of London.',
    subSteps: [
      {
        key: 'secure_shipping',
        icon: truck || '',
        btnText: 'Secure shipping',
        title: 'Secure Shipping',
        desc:
          'Prepping your jewelry for auction will require you to ship it to us. To do that you can either schedule a FedEx pickup, or drop it off at a nearby staffed FedEx location. Shipping is fully covered by Worthy and fully insured by insurers at Lloyd’s of London.',
      },
      {
        key: 'cleaning',
        icon: bubbles,
        btnText: 'Cleaning',
        title: 'Cleaning',
        desc:
          'The jewelry cleaning process involves carefully removing dirt or tarnish to improve its appearance and make it sparkle for the auction.',
      },
      {
        key: 'beauty_shots',
        icon: camera,
        btnText: 'Beauty shots',
        title: 'Beauty Shots',
        desc:
          'Your piece is professionally photographed in high-definition from every angle so that buyers can get a closer look and place high bids with confidence.',
      },
      {
        key: 'external_grading',
        icon: magnifyingGlass,
        btnText: 'External grading',
        title: 'External Grading',
        desc:
          'We partner with objective industry-leading labs such as GIA, GHI and IGI who inspect every piece we receive. Watches auctioned with us are inspected by Central Watch.',
      },
    ],
    pic: {
      desktop: 'prepping',
      mobile: '',
      breakPoint770: 'prepping770',
    },
    reverse: false,
  },
  {
    title: 'The Online Auction',
    text:
      'Once your piece is ready for auction, you can set the reserve price you’re comfortable with. We then create the auction listing for you and promote it through our buyer network. All you have to do  is start your auction and watch the offers roll in.',
    subSteps: [
      {
        key: 'auction_listing',
        icon: auctionListing,
        btnText: 'Auction listing',
        title: 'Auction Listing',
        desc:
          'We create a dedicated listing for your jewelry that includes a professional description, beauty shots and a third party grading report. Once the auction is live, buyers are able to view this listing and bid on your jewelry',
      },
      {
        key: 'reserve_price',
        icon: priceTag,
        btnText: 'Reserve price',
        title: 'Reserve Price',
        desc:
          'A reserve price is the lowest amount you would be willing to sell your jewelry for. Once the external grading report for your jewelry is ready, we will generate a recommended reserve price for you. At this point you can choose to either accept it or set your own reserve price and start your auction.',
      },
      {
        key: 'buyer_network',
        icon: auctionOffer,
        btnText: 'Buyer network',
        title: 'Buyer Network',
        desc:
          'Over 1K professional industry buyers are registered on our platform and compete for various jewelry pieces sold with us.',
      },
      {
        key: 'live auction',
        icon: click,
        btnText: 'Live auction',
        title: 'Live Auction',
        desc:
          'With the press of a button your auction will be live. Once your auction is live you will get access to a live display of it.',
      },
    ],
    pic: {
      desktop: 'auction',
      mobile: '',
      breakPoint770: 'auction770',
    },
    reverse: true,
  },
  {
    title: 'Getting Paid',
    text:
      'Shortly after the auction concludes successfully, you will receive the final sale amount minus our fee. Our fee is always a percentage of the price your valuable was sold for.',
    subSteps: [
      {
        key: 'payment_methods',
        icon: bankTransfer,
        btnText: 'Payment',
        title: 'Payment',
        desc: 'Once your piece is sold you can get your money via a bank transfer, PayPal or check. That’s up to you.',
      },
      {
        key: 'payment_time',
        icon: timer,
        btnText: 'How long it’ll take',
        title: 'How Long It’ll Take',
        desc: `Once the sale is complete, we collect payment from the buyer of your valuables. This usually occurs within 5-7 business days, often much faster. Once we have received the funds, we begin processing your payment. Receipt of the payment varies depending on the payment method you select and works as follows:
        <ul style="margin-top: ${SpacingSizes.xs}; margin-bottom: ${SpacingSizes.sm}">
          <li> Bank transfer (ACH): 1-3 business days (usually the fastest payment method) </li>
          <li> PayPal: 2-3 business days </li>
          <li> Checks: 4-5 business days </li>
        </ul>
        Payment for multiple pieces of jewelry is processed once all sales are complete.`,
      },
      {
        key: 'fees',
        icon: coins,
        btnText: 'Worthy’s fees',
        title: 'Worthy’s Fees',
        desc: renderToString(
          <>
            Our fees are subject to a successful auction and are a percentage of the final sale price. So we share a
            common interest. We also only get paid when you do.{' '}
            <p style={{ margin: `${SpacingSizes.sm} 0 ${SpacingSizes.xs}` }}> This is our fee table: </p>
            <FeesTable />
          </>
        ),
      },
    ],
    pic: {
      desktop: 'payment',
      mobile: 'paymentMobile',
      breakPoint770: 'payment770',
    },
    reverse: false,
  },
]
